import axios from "../common/AuthInterceptor";
import authHeader from "./auth-header";

const INV_API_URL = process.env.REACT_APP_API_URL + "/inventory";

const getClientInventory = (pageRequest) => {
  return axios.post(INV_API_URL, pageRequest, { headers: authHeader() });
}
const deleteParts = (clientId, id) => {
  return axios.post(INV_API_URL + "/" + clientId + "/removeInventory/" + id, {}, { headers: authHeader() });
}

const getInventoryByClientParts = (clientId, partsId) => {
  return axios.get(INV_API_URL + "/cp/" + clientId + "/" + partsId, {headers: authHeader() })
}

const updateInventory = (clientId, id, pnId, qty, cost, listPrice, location, addition) => {
  return axios.post(INV_API_URL + "/cp/" + clientId, {id, clientId, pnId, qty, location, listPrice, cost, addition}, {headers: authHeader() })
}

const addParts = (clientId, pnNumber, pnName, qty, listPrice, cost, location, makeId, modelId) => {
  return axios.post(INV_API_URL + "/" + clientId + "/newInventory", {pnNumber, pnName, qty, listPrice, cost, location, makeId, modelId}, {headers: authHeader() })
}

const inventoryService = {
  getClientInventory,
  deleteParts,
  updateInventory,
  getInventoryByClientParts,
  addParts,
};

export default inventoryService