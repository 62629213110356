import React, {useEffect, useState} from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Pagination from "react-js-pagination";

import UserService from "../services/user.service";
import {Navigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import inventoryService from "../services/inventory.service";
import InventoryItem from "./InventoryItem";
import SearchForm from "./inventory/SearchForm";
import {ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {cancelLoader, setLoader} from "../slices/spinner";
import Toaster from "../common/ToastMsg";

const Inventory = () => {
    const dispatch = useDispatch();
    const [clients, setClients] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const { user: currentUser } = useSelector((state) => state.auth);
    const [inventory, setInventory] = useState([]);

    const [filterParts, setFilterParts] = useState("");
    const [filterNumber, setFilterNumber] = useState("");
    const [filterLocation, setFilterLocation] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentEditItem, setCurrentEditItem] = useState({id: undefined, partsId: undefined, partsName: undefined, partsNumber: undefined, location: undefined, qty: undefined});

    const [timer, setTimer] = useState(null);
    const openModal = () => {
        setCurrentEditItem({id: undefined, partsId: undefined, partsName: undefined, partsNumber: undefined, location: undefined, qty: undefined});
        setIsModalOpen(true);
    };

    const [pageRequest, setPageRequest] = useState({page: 0, size: 50});
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [totalItems, setTotalItems] = useState(0);
    const [displayRange, setDisplayRange] = useState('');

    const urlSearchParams = new URLSearchParams(window.location.search);
    const client = urlSearchParams.get("client");

    const closeModal = (msg) => {
        if (msg) {
            Toaster.info({
                text: msg
            },{
                position: "top-center",
                containerId: "inventory",
                autoClose: 3000
            });
        }
        setIsModalOpen(false);
        refreshInventory();
    };

useEffect(() => {
    dispatch(setLoader());
    UserService.getClientList().then(
      (response) => {
        setClients(response.data);
        if (response.data instanceof Array && response.data.length > 0) {
            setSelectedOption(client || response.data[0].oid);
        }
          dispatch(cancelLoader());
      },
      (error) => {
          console.log(error);
          Toaster.error({
              title: "Failed to load clients !",
              text: error.message
          }, {
              position: "top-center",
              containerId: "search"
          });
          dispatch(cancelLoader());
      }
    )

  }, []);

  const refreshInventory = () => {
    dispatch(setLoader("loading inventory"));
    let newPageRequest = {...pageRequest};
    newPageRequest.clientId = selectedOption;
    newPageRequest.searchPn = filterNumber
    newPageRequest.searchDescription = filterParts;
    newPageRequest.searchLocation = filterLocation;
    inventoryService.getClientInventory(newPageRequest).then(
        (response) => {
            setInventory(response.data.content);
            setTotalPages(response.data.totalPages);
            setTotalItems(response.data.totalElements);
            let start = pageRequest.page * pageRequest.size + 1;
            const end = start + response.data.content.length - 1;
            if (end === 0) start = 0;
            setDisplayRange(`${start}-${end}`);
            dispatch(cancelLoader());
        },
        (error) => {
            console.log(error);
            Toaster.error({
                title: "Failed to load inventory !",
                text: error.message
            }, {
                position: "top-center",
                containerId: "search"
            });
            dispatch(cancelLoader());
        }
    )
}


const handlePageChange = (pageNumber) => {
    setPageRequest(prevState => ({...prevState, page: pageNumber - 1}));
}

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPageRequest(prevState => ({...prevState, size: event.target.value, page: 0}));
    }

  const refreshInventory1 = () => {
      setInventory([]);
      setFilterParts("");
      setFilterNumber("");
      setFilterLocation("");
  };
  useEffect(() => {
    if (!currentUser || !selectedOption) return;
    refreshInventory();
  }, [selectedOption, currentUser, pageRequest]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const handleSelection = (event) => {
      handleClearFilter();
      setSelectedOption(event.target.value);
  };

    const handleFilterPartsChange = (event) => {
        setFilterParts(event.target.value);
    }

    const handleFilterNumberChange = (event) => {
        setFilterNumber(event.target.value);
    }

    const handleFilterLocationChange = (event) => {
        setFilterLocation(event.target.value);
    }

    const handleClearFilter = () => {
        setFilterParts("");
        setFilterNumber("");
        setFilterLocation("");
        setPageRequest(prevState => ({...prevState, page: 0}));
    }

    const handleApplyFilter = () => {
        setPageRequest(prevState => ({...prevState, page: 0}));
    }

    return (
        <Container fluid>
            <ToastContainer autoClose={false} containerId={"inventory"} />
            <Row>
                <Col xs={12} md={8}>
                    <div className="dropdown mb-2">
                        <select
                            value={selectedOption}
                            className="form-select"
                            onChange={handleSelection}
                        >
                            {!clients &&
                                <option value="" disabled>
                                    No client found
                                </option>
                            }

                            {clients && clients.map((client, index) => (
                                <option value={client.oid}>
                                    {client.clientName}
                                </option>
                            ))}

                        </select>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div className="container">
                        <button type="button" className="btn btn-primary" onClick={openModal}>Add New Parts
                        </button>
                        {isModalOpen && (
                            <SearchForm
                                closeSearch={closeModal} clientId={selectedOption} refreshInventory={refreshInventory1}
                            />
                        )}
                    </div>
                    {/* Your content for column 2 */}
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="row mt-5 table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Parts Number</th>
                                <th scope="col">Parts</th>
                                <th scope="col">Location</th>
                                <th scope="col" style={{paddingRight: 50}}>Qty</th>
                                <th scope="col" style={{paddingRight: 60}}>List Price</th>
                                <th scope="col" style={{paddingRight: 60}}>Cost</th>
                                <th scope="col">Action</th>
                            </tr>
                            </thead>
                            <tbody>

                            <tr className="table-secondary">
                                <td></td>
                                <td className="align-content-center"><input type="text" value={filterNumber} onChange={handleFilterNumberChange}/></td>
                                <td className="align-content-center"><input type="text" value={filterParts} onChange={handleFilterPartsChange}/></td>
                                <td className="align-content-center"><input type="text" value={filterLocation} onChange={handleFilterLocationChange}/></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="col-8">
                                    <button className="btn btn-primary m-2" onClick={handleApplyFilter}>Filter</button>
                                    <button className="btn btn-secondary" onClick={handleClearFilter}>Clear</button>
                                </td>
                            </tr>

                            {inventory && inventory instanceof Array && inventory.length > 0 && inventory.map((invItem, index) => (
                                <InventoryItem key={invItem.id} clientId={selectedOption} item={invItem}
                                               inv={inventory}/>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center">
                    <Pagination
                        activePage={pageRequest.page + 1}
                        itemsCountPerPage={pageRequest.size}
                        totalItemsCount={totalPages * pageRequest.size}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                    <div className="dropdown m-2 mt-0">
                        <select
                            value={pageSize}
                            className="form-select"
                            onChange={handlePageSizeChange}
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                </Col>
                <Col>
                    <div className="d-flex mt-2">
                        <div style={{ fontFamily: 'Bolt', color: 'blue' }}>Displaying: {displayRange} of {totalItems}</div>
                        <div style={{ marginLeft: '20px', fontFamily: 'Bolt', color: 'blue' }}>Total Pages: {totalPages}</div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Inventory;
